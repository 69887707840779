import React, { useEffect, useMemo, useRef, useState } from "react";
import ToomarContainer from "../containers/ToomarContainer";
import "./Gallery.scss";
import AllFeatures from "../kit/AllFeatures";
import Close from "../../assets/circleClose.svg";
import Hamburger from "../../assets/hamburger.svg";
import Hint from "../../assets/hint.svg";
import {
  getAllProducts,
  getAllProductsWithUrl,
  getCategories,
  getCategoriesTree,
  getBanner,
  getSelectedCategories,
} from "../../redux/actions";
import { connect } from "react-redux";
import Categories from "./Categories";
import { useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
// import { orderings } from "./UIData";
import GalleryOrder from "./CalleryOrder";
import { orderings } from "./UIData";
import ToomarCardGallery from "../Utils/ToomarCardGallery";


const defaultTitle = "گالری محصولات طومار | مرجع اطلاع از قیمت و فروش کارت و اقلام عروسی";
const defaultDescription = "بالای ۱۰۰۰ مدل کارت های عروسی چاپی در هفت دسته بندی مختلف با قیمت نهایی ، گالری کارت های عروسی دیجیتال ، گیفت عقد ، دفاتر بله برون و جهیزیه ، تابلو های یاد بود مراسم ، ست های بله برون و...";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1200px",
    maxWidth: "1200px!important",
    // height: 'var(--dialog-height)',
    borderRadius: "24px",
    "@media (max-width:800px)": {
      width: "70%",
      margin: "0px !important",
    },
  },
}));

function reduceCats(prv, cur) {
  if (!cur) return prv;
  return [...prv, cur, ...cur?.children.reduce(reduceCats, [])];
}

function Gallery(props) {
  const [products, setProducts] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [banner, setBanner] = useState("");
  const [hintModal, setHintModal] = useState(true);
  const [visible, setVisible] = useState(false);
  const params = useParams();
  const classes = useStyles();

  const [allApiData, setAllApiData] = useState({});
  const listInnerRef = useRef();
  const [disableLoadMoreBtn, setDisableLoadMoreBtn] = useState(false);
  const [categoriesModal, setCategoriesModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [ordering, setOrdering] = useState("-score");

  const [title, description] = useMemo(() => {
    const flatCats = galleryCategories?.reduce(reduceCats, []);
    const currentCat = flatCats.filter(
      (item) => item.id.toString() === params.id?.toString()
    )[0];
    if (currentCat)
      return [currentCat.title || currentCat.name || defaultTitle, currentCat.description || defaultDescription];
    return [defaultTitle, defaultDescription];
  }, [galleryCategories, params]);

  useEffect(() => {
    const fetchData = async () => {
      setVisible(false)
      await props.getBanner(1, (data) => setBanner(data.data.result));
      await props.getCategoriesTree((data) =>
        setGalleryCategories(data.data.result.children)
      );
      if(params.id) {
        await props.getAllProducts(
          {category: params?.id, ordering},
          (data) => {
            setProducts(data.data.results);
            setAllApiData(data.data);
          }
        );
      }
      else {
        await props.getAllProducts(
          {category: 1, ordering},
          (data) => {
            setProducts(data.data.results);
            setAllApiData(data.data);
          }
        );
      }
      setVisible(true);
    };
    fetchData();
  }, [params, props, params.id, ordering]);

  const onLoad = async () => {
    setDisableLoadMoreBtn(true);
    if (allApiData.count > products.length) {
      await props.getAllProductsWithUrl(allApiData.next, (data) => {
        setProducts([...products, ...data.data.results]);
        setAllApiData(data.data);
      });
      setDisableLoadMoreBtn(false);
    }
  };

  const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

  return (
    <ToomarContainer>
      {showTopBtn && (
        <button
          className="icon-position icon-style"
          onClick={goToTop}
        >
          برو بالا
        </button>
      )}
      <Helmet>
        <title>
          {title}
        </title>
        <meta
          name="title"
          content={title}
        />
        <meta
          name="description"
          content={description}
        />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Dialog
        open={hintModal}
        hideBackdrop={false}
        onClose={() => setHintModal(false)}
        PaperProps={{
          className: classes.root,
        }}
      >
        <div className="hintModalContainer">
          {/* <div onClick={() => setHintModal(false)}>
            <p>بستن</p>
            <img src={Close} />
          </div> */}
          <img src={Hint} />
          <p>
            تمامی قیمت های ذکر شده ، قیمت نهایی محصولات (کارت های عروسی شامل
            هزینه چاپ و طراحی و... ) میباشد
          </p>
          <p>
              تمامی نمونه هایی که داخل سایت مشاهده میکنید موجود است و قیمت ها بروز میباشد
          </p>
          <button onClick={() => setHintModal(false)}>
            متوجه شدم
          </button>
        </div>
      </Dialog>
      <Dialog
        open={categoriesModal}
        hideBackdrop={false}
        onClose={() => setCategoriesModal(false)}
        PaperProps={{
          className: classes.root,
        }}
      >
        <div className="categoriesModalContainer">
          <div onClick={() => setCategoriesModal(false)}>
            <p>بستن</p>
            <img src={Close} />
          </div>
          <div>
            <p>دسته بندی نتایج</p>
            <div className="categoriesContainer">
              {galleryCategories.map((item) => (
                <Categories
                  key={item.id}
                  currentCategoryId={params.id}
                  data={item}
                  closeModal={() => setCategoriesModal(false)}
                />
              ))}
            </div>
          </div>
        </div>
      </Dialog>
      <div className={"galleryContainer"}>
        <a className={"banner-a"} href={banner.url} target={"_blank"}>
          <img src={banner.image} alt={"گالری کارت عروسی"} />
        </a>
        <div>
          <div>
            <div>
              <p>دسته بندی نتایج</p>
              <div className="categoriesContainer">
                {galleryCategories.map((item) => (
                  <Categories key={item.id} currentCategoryId={params.id} data={item} />
                ))}
              </div>
            </div>
          </div>
          <div>
            <div>
              <p>محبوب ترین دسته بندی ها:</p>
              <AllFeatures />
            </div>
            <div>
              <p>مرتب سازی :</p>
              <div>
                {orderings.map(({ text, value }) => (
                  <GalleryOrder
                    text={text}
                    onClick={() => setOrdering(value)}
                    active={ordering === value}
                  />
                ))}
              </div>
            </div>
            <div>
              <button onClick={() => setCategoriesModal(true)}>
                <img src={Hamburger} />
                <p>دسته بندی پیشرفته</p>
              </button>
              <select
                value={ordering}
                onChange={(e) => setOrdering(e.target.value)}
              >
                {orderings.map(({ text, value }) => (
                  <option value={value}>{text}</option>
                ))}
              </select>
            </div>
            <div
              ref={listInnerRef}
              className={visible ? "visible" : "visible hide"}
            >
              {isMobile ? (
                <div>
                  {products.map((item, index) => (
                    <ToomarCardGallery key={item.id} value={item} />
                  ))}
                </div>
              ) : (
                <>
                  <div>
                    {products.map((item, index) =>
                      index % 3 == 0 ? (
                        <ToomarCardGallery key={item.id} value={item} />
                      ) : null
                    )}
                  </div>
                  <div>
                    {products.map((item, index) =>
                      index % 3 == 1 ? (
                        <ToomarCardGallery key={item.id} value={item} />
                      ) : null
                    )}
                  </div>
                  <div>
                    {products.map((item, index) =>
                      index % 3 == 2 ? (
                        <ToomarCardGallery key={item.id} value={item} />
                      ) : null
                    )}
                  </div>
                </>
              )}
              {allApiData.count > products.length ? (
                <button onClick={onLoad} disabled={disableLoadMoreBtn}>
                  مشاهده بیشتر
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </ToomarContainer>
  );
}

export default connect(null, {
  getAllProducts,
  getCategories,
  getCategoriesTree,
  getAllProductsWithUrl,
  getBanner,
  getSelectedCategories
})(Gallery);
