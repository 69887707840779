import React from 'react';
import FollowupDrawing from '../../assets/followupDrawing.svg';
import { priceFormat } from '../Utils/PriceFormat';
function FollowUpOrderItem({
    data, 
    ...props}) {

    return (
        <div className={'followUpOrderItem'}>
            <div>
                <img src={`https://toomar.co${data.product.cover}`} alt={'کارت عروسی'}/>
                <div>
                    <p>
                        {data.product.title}
                    </p>
                    <div>
                        <p>
                            تعداد :
                        </p>
                        <div style={{fontSize: 16}}>
                            <p style={{fontSize: 24}}>{data.count}</p>
                        </div>
                        {/* {Object.keys(data.data.options).map((keyName, i) => (
                            <select defaultValue={'kabols'} value={data.options[0]} onChange={(e) => handleOptions(e)}>
                                <option disabled value={'kabols'}>
                                    {keyName}
                                </option>
                                {data.data.options[keyName].map((item, index) => (
                                <option value={item.id}>
                                    {item.value}
                                </option>
                                ))}
                            </select>
                        ))} */}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <p>
                        مبلغ کل:
                    </p>
                    <p>
                        {priceFormat(data.total_price)} تومان
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FollowUpOrderItem;