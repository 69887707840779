import React from 'react';
import './Home.scss';
import Test from '../../assets/test2.svg';
import { Link } from 'react-router-dom';
function TopFeatureOfToomar() {
    return (
            <div className={'topFeatureOfToomarContainer'}>
                <div>
                    <p>
                        خرید کارت عروسی با قیمت نهایی
                    </p>
                    <span>
                        ارسال به تمامی نقاط کشور
                    </span>
                    <span className={'leftSpan'}>
                        با قیمت تمام شده
                    </span>
                    <Link to={'/gallery'}>

                        <button>
                                سفارش کارت عروسی
                        </button>
                    </Link>

                </div>
                <img src={Test} alt={'کارت عروسی'}/>
            </div>
    )
}

export default TopFeatureOfToomar;