import React, { useEffect, useState } from 'react';
import './Magazine.scss';
import ToomarContainer from '../containers/ToomarContainer';
import TopFeatureOfToomar from '../home/TopFeatureOfToomar';
import Title from '../Utils/Title';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import ToomarBlogCard from '../Utils/ToomarBlogCard';
import Advertisement from '../../assets/advertisement.svg';
import { getBlogs } from '../../redux/actions';
import { connect } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import {Helmet} from "react-helmet";
// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function Magazine({...props}) {

    const [blogs, setBlogs] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        props.getBlogs(undefined, (data) => setBlogs(data.data.results));
    }, {})

    return (
        <ToomarContainer>
            <Helmet>
                <title>مجله طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی</title>
                <meta name="title" content="مجله طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی" />
                <meta name="description" content="جدید ترین اخبار مربوط به عروسی و نکاتی بسیار مهم راجب انتخاب کارت و اقلام عروسی ، همه در مجله طومار" />
                <meta property="og:title" content="مجله" />
                <meta property="og:url" content="https://toomar.co/magazine" />
            </Helmet>
            {blogs.length != 0 ?
            <div className={'magazineContainer'}>
                <img src={Advertisement} alt={'advertisement'}/>
                <Title blackText={'طومار'} goldText={'مجله‌ی'}/>
                <Swiper cssMode={true} slidesPerView={isMobile ? 1 : 3} navigation={true}  pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper"}>
                    {blogs.map(item => 
                    <SwiperSlide>
                        <ToomarBlogCard data={item}/>
                    </SwiperSlide>
                    )}
                </Swiper>
            </div>
            : null}
            <TopFeatureOfToomar/>
            {blogs.length != 0 ?
            <div className={'magazineContainer'}>
                <Title blackText={'طومار'} goldText={'جدیدترین اخبار'}/>
                <Swiper cssMode={true} slidesPerView={isMobile ? 1 : 3} navigation={true}  pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper"}>
                    {blogs.map(item => 
                        <SwiperSlide>
                            <ToomarBlogCard data={item}/>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            : null}
        </ToomarContainer>
    )
}

export default connect(null, { getBlogs })(Magazine);