import React from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import Title from '../Utils/Title';
import './Laws.scss';
import FollowUsInSocials from '../kit/FollowUsInSocials';

function Laws() {
    return (
        <ToomarContainer>
            <Title
            goldText={'قوانین و '}
            blackText={'مقررات'}/>
            <div className={'lawsContainer'}>
                <p>
                    <span>۱ـ </span>مشتری گرامی مونتاژ (سر هم کردن) کلیه کارت های چاپی طبق روال بازار به عهده شما مشتریان عزیز می‌باشد.
                </p>
                <p>
                    <span>۲ـ </span>شرکت طومار تضمین میکند دقیقا همان تصویری که در عکس نمونه مشاهده کرده اید، مطابق با اطلاعات ثبت شده شما تحویل دهد.
                </p>
                <p>
                    <span>۳ـ </span> قبل از چاپ یا خروجی گرفتن محصول شما مشتری عزیز، تیم پشتیبانی طومار از طریق تلگرام،واتساپ یا.... شماره‌ی ثبت شده با شما در ارتباط خواهد بود و نمونه متن طراحی شده را برای شما ارسال می‌کند و تا ۳ مرتبه میتوانید بدون هزینه تغییراتی که مدنظر دارید را با طراحان مجموعه در میان گذاشته تا اعمال شوند، سپس بعد از تاییدیه شما وارد مرحله چاپ یا خروجی کارت می‌شود.
                </p>
                <p>
                    <span>۴ـ </span> شرکت طومار تا ۲۴ ساعت پس از تحویل محصولات شما مشتریان عزیز مسئولیت هر گونه مشکل در تعداد و خرابی های احتمالی را به عهده میگیرد.
                </p>
                <p>
                    <span>۵ـ </span>هزینه ارسال برای عزیزانی که داخل تهران هستند و بالای یک میلیون ۵۰۰ هزار تومان خرید از سایت انجام داده اند ، بصورت رایگان میباشد(ارسال با تیپاکس)و عزیزانی که خارج از تهران تشریف دارند ، درصورت خرید بالای ۳ میلیون تومان از سایت ، هزینه ارسالشان رایگان میباشد(ارسال با تیپاکس)
                </p>
                <p>
                    <span>۶ـ </span>فرایند آماده سازی تا تحویل تمامی محصولات؛ برای سفارشات داخل تهران ۳ الی ۵ روز کاری و خارج تهران ۴ الی ۷ روز کاری از لحظه ثبت سفارش میباشد بجز کارت های عروسی دیجیتال که ظرف ۴۸ ساعت کاری و طراحی اعلامیه که ۱۲ ساعته آماده میشود .
                </p>
                <p>
                    <span>کلام آخر: </span>سپاس فراوان از شما عزیزان که به ما اعتماد و سایت طومار را برای خرید انتخاب کردید. تیم ما همه‌ی تلاش خود را میکند تا کار شما مشتری عزیز را به نحو احسن انجام دهد. امیدواریم ثمره‌ی کار ما رضایت قلبی شما عزیزان باشد.                    
                </p>
                <p style={{margin: "0px"}}>
                    عرفان مهری
                </p>
                <p>مدیریت مجموعه طومار</p>
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default Laws;