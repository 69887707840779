import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import ToomarUnderlineSmooth from '../../assets/toomarUnderlineSmooth.svg';
import { getCardTexts, getMoreCardTexts } from '../../redux/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ToomarInput from '../kit/ToomarInput';

function SelectText({setStep, chooseText, customText, setCustomText, setChooseText, ...props}) {
    const [whichMenu, setWhichMenu] = useState(0);
    const [allTexts, setAllTexts] = useState([]);
    const [allApiData, setAllApiData] = useState({});
    const listInnerRef = useRef();
    const params = useParams();
    useEffect(() => {
        props.getCardTexts(undefined, (data) => {
            for(let i = 0; i < data.data.results.length; i++) {
                if(data.data.results[i].id == chooseText?.id) {
                    setChooseText(data.data.results[i]);
                }
            }
            setAllTexts(data.data.results);
            setAllApiData(data.data)
        })
    }, {})
    
    useEffect(() => {
        if(whichMenu === 3) {
            setChooseText(null);
        }
    }, [whichMenu])

    const handleGoNext = (item = null) => {
        setChooseText(item.id != undefined ? item : chooseText)
        if(chooseText !== '' || item.id != undefined) {
            for(let i = 0; i < allTexts.length; i++) {
                if(allTexts[i].id == chooseText?.id) {
                    setChooseText(allTexts[i])
                }
            }
            if(props.buyingProducts[params.id - 1].data.is_digital) {
                if(props.justThisStep == true) {
                    props.setJustThisStep(false)
                    setStep(4);
                }
                else {
                    setStep(3)
                }
            }
            else {
                if(props.justThisStep == true) {
                    props.setJustThisStep(false)
                    setStep(4);
                }
                else {
                    setStep(2)
                }
            }
        }
        else if((chooseText === null && customText !== '')) {
            if(props.buyingProducts[params.id - 1].data.is_digital) {
                if(props.justThisStep == true) {
                    props.setJustThisStep(false)
                    setStep(4);
                }
                else {
                    setStep(3)
                }
            }
            else {
                if(props.justThisStep == true) {
                    props.setJustThisStep(false)
                    setStep(4);
                }
                else {
                    setStep(2)
                }
            }
        }
        else {
            toast.error('اطلاعات را کامل کنید')
        }
    }

    const handleSetCategoryFilter = async (filter) => {
        setWhichMenu(filter)
        setAllTexts([]);
        await props.getCardTexts(`type=${filter}`, (data) => {setAllTexts(data.data.results); setAllApiData(data.data)})
    }

    const onScroll = async() => {
        if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
          if ((scrollTop + clientHeight === scrollHeight || scrollHeight - scrollTop - clientHeight < 5) && allApiData.count >= allTexts.length ) {
            if(whichMenu == '') {
                await props.getMoreCardTexts(undefined, allApiData.next, (data) => {setAllTexts([...allTexts, ...data.data.results]); setAllApiData(data.data)})
            }
            else {
                await props.getMoreCardTexts(`type=${whichMenu}`, allApiData.next, (data) => {setAllTexts([...allTexts, ...data.data.results]); setAllApiData(data.data)})
            }
          }
        }
      };

    return (
        <div className={'selectTextContainer'}>
            <div>
                <p>
                    مرتب سازی :
                </p>
                <div>
                    <div onClick={() => handleSetCategoryFilter(0)}>
                        <p className={whichMenu === 0 ? 'isActive' : 'isNotActive'}>
                            متن
                        </p>
                        {whichMenu === 0 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => handleSetCategoryFilter(1)}>
                        <p className={whichMenu === 1 ? 'isActive' : 'isNotActive'}>
                            شعر
                        </p>
                        {whichMenu === 1 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => handleSetCategoryFilter(2)}>
                        <p className={whichMenu === 2 ? 'isActive' : 'isNotActive'}>
                            متن و شعر
                        </p>
                        {whichMenu === 2 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => handleSetCategoryFilter(3)}>
                        <p className={whichMenu === 3 ? 'isActive' : 'isNotActive'}>
                            دلخواه
                        </p>
                        {whichMenu === 3 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    
                </div>
            </div>
            {whichMenu !== 3 ? 
            <>
                <p>
                    متن مورد نظر خود را انتخاب نمایید.
                </p>
                <div
                onScroll={onScroll}
                ref={listInnerRef} 
                >
                    {allTexts.map(item => (
                        <div>
                            <img src={item.image} alt={'کارت عروسی'}/>
                            <button className={item?.id == chooseText?.id ? 'selected' : null} onClick={() => {setChooseText(item); setCustomText(""); handleGoNext(item)}}>
                                انتخاب
                            </button>
                        </div>
                    ))}
                </div>
            </>
            :
            <ToomarInput
            title={'لطفا متن دلخواه خود را وارد کنید'}
            disabled={false}
            inputValue={customText}
            onChange={(e) => setCustomText(e.target.value)}
            />
            }
            <div>
                {/* <button>
                    بازگشت
                </button> */}
                <button onClick={handleGoNext}>
                    تایید و ادامه
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    buyingProducts: state.counter.buyingProducts,
})

export default connect(mapStateToProps, { getCardTexts, getMoreCardTexts })(SelectText);
