import React from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import Title from '../Utils/Title';
import './AboutUs.scss';
import FollowUsInSocials from '../kit/FollowUsInSocials';
import {Helmet} from "react-helmet";

function AboutUs() {
    return (
        <ToomarContainer>
            <Helmet>
                <title>درباره طومار | بزرگترین فروشگاه اینترنتی عروسی</title>
                <meta name="title" content="درباره طومار | بزرگترین فروشگاه اینترنتی عروسی" />
                <meta name="description" content="فروشگاه اینترنتی طومار ، به عنوان بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی در خدمت شما عزیزان میباشد که تمامی اقلام مربوط به مراسم عروسی را در بستر فروش آنلاین به شما عزیزان ارائه میدهد" />
                <meta property="og:title" content="درباره ما" />
                <meta property="og:url" content="https://toomar.co/about-us" />
            </Helmet>
            <Title
            goldText={'درباره'}
            blackText={'ما'}/>
            <div className={'aboutUsContainer'}>
                <p>
                    با سلام خدمت همه ی عزیزان بالخصوص زوج های عزیز . فروشگاه اینترنتی کارت عروسی طومار به عنوان بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی با ایجاد محیطی مناسب و ساده در فضای مجازی در خدمت شما میباشد .
                </p>
                <p>
                    شما زوج های عزیز میتوانید فقط با چند کلیک در هر زمان و مکانی که باشید ، کارت خودرا سفارش دهید ، و همه ی جزئیات کارت خود را به دلخواه خود انتخاب کنید به علاوه ما در طومار تمامی اقلام مورد نیاز برای جشن های مختلف ازدواج را فراهم کرده ایم تا بدون استرس بتوانید کلیه وسایل مورد نیاز خود را سفارش دهید و با بالاترین کیفیت آن ها را آماده مراسم خود کنید.
                </p>
                <p>
                    محدوده فعالیت طومار در حال حاضر در ایران در تمامی شهرها و استان ها میباشد و شما از هر جای ایران عزیز که سفارش بدهید تیم حرفه ای طومار همه ی تلاش خود را میکند تا کار شما مشتری عزیز را به نحو احسن انجام دهد .(البته اگر در خارج از ایران هم سکونت دارید میتوانید با شماره های موجود روی سایت تماس بگیرید و محصولات مورد نظر خود را از طریق تماس تلفنی یا واتساپ سفارش دهید.)
                </p>
                <p>
                    بزرگ ترین هدف شرکت طومار رضایت مندی و خوشنودی شما مشتری عزیز میباشد ، تجربه یک خرید خوب ، تحویل، کیفیت خوب و رضایت مندی شما با ارزش ترین دارایی ما حساب میشود .
                </p>
                <p>
                    تیم طومار نظرات و پیشنهادات شما عزیزان را یکی از بزرگترین دارایی های خود میداند . ما از زوجین عزیزی که نظرات خود را با ما درجریان میگذارند و مارا نقد و برسی میکنند کمال تشکر و قدر دانی را داریم . شما میتوانید در قسمت {<span onClick={() => window.open('/offers-critics/')}>پیشنهادات و انتقادات</span>} پیام خود را ثبت کنید و تیم ما حتما بررسی های لازم را انجام خواهد داد و نظرات شما عزیزان در روند اصلاح سایت و بهبود فعالیت ما بسیار موثر میباشد . 
                </p>
                <p>
                سایت طومار به گونه ای طراحی شده است که شما زوج های عزیز میتوانید به راحتی کارت عروسی و اقلام مورد نظر خود را پیدا کنید و در مرحله بعدی متن و شعر کارت خود را از گالری انتخاب کنیدو سپس نوع چاپ متن و نوع چاپ لاتین و تیتر کارت و در مرحله بعد ثبت اطلاعات را انجام داده و سفارش خود را نهایی کنید ، برای اطلاع بیشتر از نحوه ثبت سفارش میتوانید به قسمت {<span onClick={() => window.open('/order-guide/')}>راهنمای ثبت سفارش</span>} مراجعه کنید.
                </p>
                <p>
                تیم طومار امید دارد که با فراهم آوردن کارت عروسی و وسایل مورد نیاز مراسم شما سهم کوچکی در خوشنودی و به یادماندنی تر کردن مراسمات شما داشته باشد.
                </p>
                <p>
                ارادتمند شما: مدیریت طومار(عرفان مهری)
                </p>
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default AboutUs;