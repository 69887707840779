import React, { useState } from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import './FollowUpOrder.scss';
import FollowupDrawing from '../../assets/followupDrawing.svg';
import FollowupSending from '../../assets/followupSending.svg';
import FollowupFailed from '../../assets/followupFailed.svg';
import FollowUpProcess from '../../assets/followUpProcess.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllFeatures from '../kit/AllFeatures';
import { followupOrderStatus } from '../../redux/actions';
import { connect } from 'react-redux';
import FollowUpOrderItem from './FollowUpOrderItem';
import Tick from '../../assets/tick.svg';

function FollowUpOrder({...props}) {
    const [trackingCode, setTrackingCode] = useState('');
    const [submitPressed, setSubmitPressed] = useState(false);
    const [statusData, setStatusData] = useState({});
    const [apiData, setApiData] = useState();
    const handleSubmitPressed = () => {
        if(trackingCode !== '') {
            props.followupOrderStatus(trackingCode, (data) => {
                if(data.data.status == 'success') {
                    setSubmitPressed(true);
                    setStatusData(data.data.result);
                    setApiData(data.data);
                }
                else {
                    toast.error('کد رهگیری نامعتبر است');
                }
            }, () => toast.error('کد رهگیری نامعتبر است'));
        }
        else toast.error('کد رهگیری را وارد کنید');

    }

    return (
        <ToomarContainer>
            <ToastContainer />
            {submitPressed ?
                <>
                    <div className={'progressBarContainerForFollowUpOrder'}>
                        <div>
                            {/* <div style={statusData >= 1 ? {backgroundColor: "green"} : null}/>
                            <div style={statusData >= 1 ? {backgroundColor: "green"} : null}/> */}
                            <div style={statusData >= 2 ? {backgroundColor: "green"} : statusData === 1 ? {backgroundColor: "#D45A07"} : null}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                            <div style={statusData >= 2 ? {backgroundColor: "green"} : null}/>
                            <div style={statusData >= 3 ? {backgroundColor: "green"} : statusData === 2 ? {backgroundColor: "#D45A07"} : null}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                            <div style={statusData >= 3 ? {backgroundColor: "green"} : null}/>
                            <div style={statusData >= 3 ? {backgroundColor: "green"} : statusData === 3 ? {backgroundColor: "#D45A07"} : null}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                        </div>
                        <div>
                            {/* <p>
                            ناموفق
                            </p> */}
                            <p>
                            پردازش
                            </p>
                            <p>
                            آماده سازی و طراحی
                            </p>
                            <p>
                            ارسال و بسته‌بندی
                            </p>
                        </div>
                    </div>
                    <div className={'followUpItemsContainer'}>
                        <p>
                            سبد خرید 
                        </p>
                        {apiData && apiData.products.map(item => (
                            <FollowUpOrderItem 
                            data={item} 
                            />
                        ))}
                        <p style={{border: "none", paddingBottom: 0}}>
                            اطلاعات خریدار 
                        </p>
                        <div>
                            {apiData &&
                                <>
                                    <div>
                                        <p>
                                            نام:
                                        </p>
                                        <p>
                                            {apiData.buyer.name}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            شماره تلفن:
                                        </p>
                                        <p>
                                            {apiData.buyer.phone_number}
                                        </p>
                                    </div>
                                    {apiData.buyer.address != "" &&
                                    <div>
                                        <p>
                                            آدرس:
                                        </p>
                                        <p>
                                            {apiData.buyer.address}
                                        </p>
                                    </div>
                                    }
                                </>
                            }
                            
                        </div>
                    </div>
                    <div className="followUpDetails">
                        <p>
                            کاربر عزیز 
                        </p>
                        <p>
                            سفارش شما با موفقیت داخل سایت ثبت شده ، مرسی از اعتمادتون 🙏
                        </p>
                    </div>
                    <div className={'followUpOrderContainer'}>
                        <div>
                            <img src={statusData == 1 ? FollowUpProcess : statusData == 2 ? FollowupDrawing : statusData == 0 ? FollowupFailed : FollowupSending } alt={'state'}/>
                            <p>
                                 مشتری گرامی سفارش شما در وضعیت 
                                <span style={statusData == 0 ? { color: 'red'} : statusData == 3 ? { color: 'green' } : { color: 'orange' } }>
                                    {statusData == 0 ? ' ناموفق ' : statusData == 1 ? ' پردازش ' : statusData == 2 ? ' آماده سازی و طراحی ' : ' ارسال و بسته‌بندی '}
                                </span> 
                                قرار دارد.
                            </p>
                            <p>
                                شماره پیگیری سفارش شما
                            </p>
                            <p>
                                {trackingCode}
                            </p>
                        </div>
                    </div>
                    <div className="followUpDetails" style={{marginBottom: 40}}>
                        <p>
                            توجه داشته باشید که ؛ 
                        </p>
                        <p>
                            <img src={require("../../assets/redLink.png")}/>
                            مدت زمان آماده سازی و ارسال محصولات برای سفارشات داخل تهران 3 الی 5 روز کاری از لحظه ثبت سفارش و برای خارج از تهران 4 الی 7 روز کاری میباشد.
                        </p>
                        <p>
                            <img src={require("../../assets/redLink.png")}/>
                            در کلیه سفارشات چاپی مثل کارت عروسی چاپی و دیجیتال قبل از چاپ و نهایی کردن طرح ، عکس متن طراحی شده (در تلگرام ، واتساپ یا سایر پیام رسان‌ها)برایتان ارسال میشود که تایید کنید.
                        </p>
                        <p>
                            <img src={require("../../assets/redLink.png")}/>
                            سفارشات کارت عروسی دیجیتال کلیپی و یا عکسی طی ۴۸ ساعت کاری انجام میگردد .
                        </p>
                        <p>
                            <img src={require("../../assets/redLink.png")}/>
                            در صورتی که نیاز به ارسال فایل عکس، آهنگ یا سایر اطلاعات تکمیلی برای سفارش خود دارید، منتظر باشید تا تیم طراحی در پیام رسان(تلگرام، واتساپ و ...)طی ۴۸ ساعت پس از ثبت سفارش به شما پیام دهند تا اطلاعات تکمیلی از شما دریافت شود.
                        </p>
                    </div>
                    {/* <AllFeatures/> */}
                </>
            : 
                <div className={'enterTrackingCodeContainer'}>
                    <input
                    placeholder='کد رهگیری خود را وارد کنید'
                    value={trackingCode}
                    onChange={(e) => setTrackingCode(e.target.value)}
                    />
                    <button onClick={handleSubmitPressed}>
                        پیگیری
                    </button>
                </div>
            }
        </ToomarContainer>
    )
}

export default connect(null, { followupOrderStatus })(FollowUpOrder);