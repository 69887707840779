import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ToomarContainer from '../containers/ToomarContainer';
import RadioButtonWithOrangeTick from '../kit/RadioButtonWithOrangeTick';
import ToomarInput from '../kit/ToomarInput';
import './Cart.scss';
import CartItem from './CartItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setBuyingProduct, setFinishedProductDetails, setProductObjects, submitBuyProducts } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../constants';
import { priceFormat } from '../Utils/PriceFormat';
import OrangeTick from '../../assets/orangeTick.svg';

function Cart({ ...props }) {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [acceptLow, setAcceptLow] = useState(false);
    const [allPrice, setAllPrice] = useState(0);
    const [allDiscounted, setAllDiscounted] = useState(0);
    const [shippingCount, setShippingCount] = useState(95000);
    const [isTehran, setIsTehran] = useState(false);
    const [sendMethod, setSendMethod] = useState(0);
    const [allIsDigital, setAllIsDigital] = useState(true);
    const [communicationWay, setCommunicationWay] = useState(2);
    const [koobPrice, setKoobPrice] = useState(0);
    const search = useLocation().search;
    const queryStep = new URLSearchParams(search).get('success');

    useEffect(() => {
        if(isTehran == true && allPrice - allDiscounted + koobPrice > 1500000) {
            setShippingCount(0);
        }
        else if(isTehran == false && allPrice - allDiscounted + koobPrice > 3000000) {
            setShippingCount(0);
        }
        for(let i = 0; i < props.buyingProducts.length; i++) {
            if(props.buyingProducts[i].data.is_digital != true) {
                setAllIsDigital(false);
            }
        }
        handleKoobPrice();
    }, [allPrice])

    const handleKoobPrice = () => {
        var locKoobPrice = 0;
        for(let i = 0; i < props.finishedProductDetails.length; i++) {
            if(props.finishedProductDetails[i].koob == 5 || props.finishedProductDetails[i].koob == 6) {
                setKoobPrice(locKoobPrice + Math.ceil(parseInt(props.finishedProductDetails[i].count)/100) * 595000)
                locKoobPrice = locKoobPrice + Math.ceil(parseInt(props.finishedProductDetails[i].count)/100) * 595000
            }
        }
    }

    // useEffect(() => {
    //     // props.setFinishedProductDetails([]);
    //     if(queryStep == 'yes') {
    //         toast.success('پرداخت شما با موفقیت انجام شد')
    //         props.setBuyingProduct([]);
    //         props.setFinishedProductDetails([]);
    //     }
    // }, {})
    const handlePay = () => {
        let condition = props.buyingProducts.every((item => (
            props.finishedProductDetails.some(value => (value.product == item.data.id))
        )))
        if(condition) {

            if(allIsDigital == false && name != '' && phoneNumber != '' && phoneNumber.length == 11 && province != '' && city != '' && address != '') {
                if(acceptLow == false) {
                    toast.error('پذیرفتن قوانین الزامی است')
                }
                else {
                    let data = {
                        "name": name,
                        "phone_number": phoneNumber,
                        "address": address,
                        "province": province,
                        "city": city,
                        "is_tehran": isTehran,
                        "transform": sendMethod, // باربری: ۰, تیپاکس: ۱, پست: ۲
                        "redirect": `${baseUrl}/followup-last-order`,
                        "description": description,
                        "communicationWay": communicationWay === 0 ? "ایتا" : communicationWay === 1 ? "تلگرام" : communicationWay === 2 ? "واتساپ" : "سروش",
                        "orders": [
                            ...props.finishedProductDetails
                        ]
                    }
                    props.submitBuyProducts(data, (value) => window.location.assign(value.data.payment_link), () => toast.error('خطایی رخ داده است'))
                }
            }
            else if(allIsDigital == true && name != '' && phoneNumber != '' && phoneNumber.length == 11) {
                if(acceptLow == false) {
                    toast.error('پذیرفتن قوانین الزامی است')
                }
                else {
                    let data = {
                        "name": name,
                        "phone_number": phoneNumber,
                        "address": address,
                        "is_tehran": isTehran,
                        "transform": sendMethod, // باربری: ۰, تیپاکس: ۱, پست: ۲
                        "redirect": `${baseUrl}/followup-last-order`,
                        "communicationWay": communicationWay === 0 ? "ایتا" : communicationWay === 1 ? "تلگرام" : communicationWay === 2 ? "واتساپ" : "سروش",
                        "orders": [
                            ...props.finishedProductDetails
                        ]
                    }
                    props.submitBuyProducts(data, (value) => window.location.assign(value.data.payment_link), () => toast.error('خطایی رخ داده است'))
                }
            }
            else {
                toast.error('لطفا اطلاعات خریدار را کامل کنید')
            }
        }
        else {
            toast.error('لطفا اطلاعات همه محصولات را کامل کنید')
        }
    }

    const handleSendMethod = (value) => {
        if(value == 1) {
            setSendMethod(value);
            if(allPrice - allDiscounted + koobPrice > 3000000) {
                setShippingCount(0);
            }
            else setShippingCount(95000);
        }
        else if(value == 0) {
            setSendMethod(value);
            if(allPrice - allDiscounted + koobPrice > 3000000) {
                setShippingCount(0);
            }
            else setShippingCount(0);
        }
        // else if(value == 2) {
        //     setSendMethod(value);
        //     if(allPrice - allDiscounted + koobPrice > 1000000) {
        //         setShippingCount(0);
        //     }
        //     else setShippingCount(45000);
        // }
    }
    const handleDeleteProduct = (data, index) => {
        let locProducts = [];
        let locFinishedProducts = [];
        let locProductOptions = [];
        locProducts = [...props.buyingProducts]
        locFinishedProducts = [...props.finishedProductDetails];
        if(props.productObj) {
            locProductOptions = [...props.productObj];
        }
        for(let i = 0; i < props.finishedProductDetails.length; i++) {
            if(props.finishedProductDetails[i].product == data.data.id) {
                locFinishedProducts.splice(i, 1);
                props.setFinishedProductDetails([...locFinishedProducts])
                if(props.finishedProductDetails[i].koob == 5 || props.finishedProductDetails[i].koob == 6) {
                    setKoobPrice(koobPrice - Math.ceil(parseInt(props.finishedProductDetails[i].count)/100)*595000)
                }
            }
        }
        for(let i = 0; i < props.productObj?.length; i++) {
            if(props.productObj[i]?.id == data.data.id) {
                locProductOptions.splice(i, 1);
                props.setProductObjects([...locFinishedProducts])
            }
        }
        locProducts.splice(index, 1);
        props.setBuyingProduct([...locProducts])
        var localAllPrice = 0;
        var localAllDiscount = 0;
        for(let i = 0; i < locProducts.length; i++) {
            localAllPrice = localAllPrice + locProducts[i].data.price * locProducts[i].count + locProducts[i].extraPrice
            localAllDiscount = localAllDiscount + locProducts[i].data.discount/100 * locProducts[i].data.price * locProducts[i].count
        }
        setAllPrice(localAllPrice);
        setAllDiscounted(localAllDiscount);
        if(isTehran && localAllPrice - localAllDiscount > 1500000) {
            setShippingCount(0)
        }
        else if(isTehran && localAllPrice - localAllDiscount > 1500000) {
            setShippingCount(0)
        }
        else if(isTehran && localAllPrice - localAllDiscount <= 1500000) {
            setShippingCount(65000);
        }
        else if(sendMethod == 1) {
            setShippingCount(95000);
        }
        else if(sendMethod == 0) {
            setShippingCount(0);
        }
    }

    const handleIsTehran = () => {
        setIsTehran(!isTehran);
        if(!isTehran == true && allPrice - allDiscounted + koobPrice > 3000000) {
            setShippingCount(0)
        }
        else if(!isTehran == false && allPrice - allDiscounted + koobPrice > 3000000) {
            setShippingCount(0)
        }
        else if(!isTehran == true && allPrice - allDiscounted + koobPrice <= 3000000) {
            setShippingCount(95000);
        }
        else if(sendMethod == 1) {
            setShippingCount(95000);
        }
        else if(sendMethod == 0) {
            setShippingCount(0);
        }
        
    }


    return (
        <ToomarContainer>
            <ToastContainer/>
            <div className={'cartContainer'}>
                <p>
                    سبد خرید 
                </p>
                {props.buyingProducts.map(((item, index) => 
                    <CartItem 
                    key={item.data.id}
                    data={item} 
                    index={index}
                    allPrice={allPrice}
                    allDiscounted={allDiscounted}
                    setAllPrice={value => setAllPrice(value)}
                    setAllDiscounted={value => setAllDiscounted(value)}
                    setShippingCount={value => setShippingCount(value)}
                    handleDeleteProduct={(data, index) => handleDeleteProduct(data, index)}
                    handleKoobPrice={handleKoobPrice}
                    />
                ))}
                <div>
                    <div>
                        <p>
                            اطلاعات خریدار
                        </p>
                        <div>
                            <ToomarInput
                            title={'نام و نام خانوادگی'}
                            disabled={false}
                            inputValue={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                            <ToomarInput
                            title={'شماره موبایل'}
                            disabled={false}
                            inputValue={phoneNumber}
                            isNumber={true}
                            maxLength={11}
                            errorMessage={phoneNumber.length != 11 ? "تلفن همراه باید ۱۱ رقمی باشد" : ""}
                            onChange={(e) => e.target.value.length <= 11 ? setPhoneNumber(e.target.value) : null}
                            />
                        </div>
                        <div>
                            <ToomarInput
                            title={'نام استان'}
                            disabled={allIsDigital == true ? true : false}
                            inputValue={province}
                            onChange={(e) => setProvince(e.target.value)}
                            />
                            <ToomarInput
                            title={'نام شهر'}
                            disabled={allIsDigital == true ? true : false}
                            inputValue={city}
                            onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                        <div>
                            <ToomarInput
                            title={'آدرس(آدرس را به صورت دقیق وارد کنید)'}
                            disabled={allIsDigital == true ? true : false}
                            inputValue={address}
                            onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                        <div>
                            <ToomarInput
                            title={'توضیحات'}
                            // disabled={allIsDigital == true ? true : false}
                            inputValue={description}
                            onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <RadioButtonWithOrangeTick
                        title={`تحویل در شهر تهران(مناطق ۲۰ گانه)`}
                        checked={allIsDigital == false ? isTehran : false}
                        onChange={handleIsTehran}
                        />
                        <div className="communicationWay">
                            <p>انتخاب  راه ارتباطی</p>
                            <select value={communicationWay} onChange={(e) => setCommunicationWay(e.target.value)}>
                                <option value={1}>
                                    تلگرام
                                </option>
                                <option value={2}>
                                    واتساپ
                                </option>
                                <option value={0}>
                                    ایتا
                                </option>
                                <option value={3}>
                                    سروش
                                </option>
                            </select>
                        </div>
                        {isTehran == true && allIsDigital == false ?
                        <div>
                            <p>ارسال توسط تیپاکس</p>
                            <p>
                                {allPrice - allDiscounted + koobPrice  > 1500000 ? 'رایگان' : `${priceFormat(65000)} تومان`}
                            </p>
                        </div>
                        // : allPrice - allDiscounted + koobPrice > 3000000 && allIsDigital == false ?
                        // <div>
                        //     <p>ارسال توسط تیپاکس</p>
                        //     <p>
                        //         {allPrice - allDiscounted + koobPrice  > 3000000 ? 'رایگان' : `${priceFormat(95000)} تومان`}
                        //     </p>
                        // </div>
                        : allIsDigital == false ?
                        <div className="communicationWay">
                            <p>انتخاب روش ارسال</p>
                            <select value={sendMethod} onChange={(e) => handleSendMethod(e.target.value)}>
                                <option value={1}>
                                    {allPrice - allDiscounted + koobPrice > 3000000 ? "تیپاکس(رایگان)" : "تیپاکس(۹۵,۰۰۰ هزار تومان - توصیه میشود)"}
                                </option>
                                {/* <option value={2}>
                                    پست پیشتاز(۴۵,۰۰۰)
                                </option> */}
                                <option value={0}>
                                باربری(پس کرایه - تحویل در ترمینال)
                                </option>
                            </select>
                        </div>
                        : <p></p>
                        }
                        <p>
                            {/* در نظر داشته باشید بسته های شهر تهران 2 الی 3 روز کاری و در شهرستان ها 3 تا 5 روز کاری زمان بر است. */}
                        </p>
                        <div className={'radioButtonContainer'}
                        onClick={() => setAcceptLow(!acceptLow)}
                        >
                            <button>
                                {acceptLow ? <img src={OrangeTick} alt={'checking'}/> : null}
                            </button>
                            <p>
                                <span onClick={() => window.open('/laws/')} style={{color: '#916F40', cursor: 'pointer'}}>قوانین و مقررات</span> سایت را مطالعه کرده ام و قبول می کنم.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p>
                            مجموع کل
                        </p>
                        <div>
                            <p>
                                مبلغ 
                            </p>
                            <p>
                                {`${priceFormat(allPrice)} تومان`}
                            </p>
                        </div>
                        <div>
                            <p>
                                هزینه چاپ برجسته 
                            </p>
                            <p>
                                {`${priceFormat(koobPrice)} تومان`}
                            </p>
                        </div>
                        <div>
                            <p>
                                هزینه ارسال 
                            </p>
                            <p>
                                {isTehran == true && allPrice - allDiscounted + koobPrice  > 1500000 ? 'رایگان' : isTehran != true && allPrice - allDiscounted + koobPrice  > 3000000 ? 'رایگان' : allIsDigital == true ? '۰ تومان' : `${priceFormat(shippingCount)} تومان`}
                            </p>
                        </div>
                        <div>
                            <p>
                                تخفیف 
                            </p>
                            <p>
                                {`${priceFormat(allDiscounted)} تومان`}
                            </p>
                        </div>
                        {/* <div>
                            <p>
                                شماره سفارش 
                            </p>
                            <p>
                                ۱۲۴۹
                            </p>
                        </div> */}
                        <div>
                            <p>
                                مبلغ کل 
                            </p>
                            <p>
                                {allIsDigital == true ? `${priceFormat(allPrice - allDiscounted + koobPrice)} تومان` : `${priceFormat(allPrice - allDiscounted + shippingCount + koobPrice)} تومان`}
                            </p>
                        </div>
                        <p>
                        در صورتی که نیاز به مشاوره یا راهنمایی دارید با ما <span onClick={() => window.open('/contact-us/')} style={{color: '#916F40', cursor: 'pointer'}}>تماس</span> بگیرید.
                        </p>
                        <button onClick={handlePay}>
                            پرداخت
                        </button>
                    </div>
                </div>
            </div>
        </ToomarContainer>
    )
}

const mapStateToProps = (state) => ({
    buyingProducts: state.counter.buyingProducts,
    finishedProductDetails: state.counter.finishedProductDetails,
    productObj: state.counter.productObj
})

export default connect(mapStateToProps, { submitBuyProducts, setBuyingProduct, setFinishedProductDetails, setProductObjects })(Cart);