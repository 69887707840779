import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getQuestions } from '../../redux/actions';
import CollapseBar from '../Utils/CollapseBar';
import Title from '../Utils/Title';
import './Home.scss';

function RepetitiveQuestions({...props}) {
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        props.getQuestions((data) => setQuestions(data.data.result))
    }, {})
    return (
        <div className={'repetitiveQuestionsContainer'}>
            <Title blackText={'سوالات'} goldText={'پر تکرار'}/>
            {questions.map((question, index) => (
                index <= 2 ? <CollapseBar question={question.question} answer={question.answer}/> : null
            ))}
            <Link to={'repetitive-questions'}>
                <button>                   
                    مشاهده همه سوالات                      
                </button>
            </Link>
        </div>

    )
}

export default connect(null, {getQuestions})(RepetitiveQuestions);