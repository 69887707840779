import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllProducts, getBlogs, getCategories, getSliders } from '../../redux/actions';
import ToomarContainer from '../containers/ToomarContainer';
import BestSelling from './BestSelling';
import HottestOfToomar from './HottestOfToomar';
import RepetitiveQuestions from './RepetitiveQuestions';
import ToomarCardsSwiper from './ToomarCardsSwiper';
import ToomarGallery from './ToomarGallery';
import ToomarMagazine from './ToomarMagazine';
import TopFeatureOfToomar from './TopFeatureOfToomar';
import WhyToomar from './WhyToomar';
import Loading from '../../assets/loadingHome.gif';
import {Helmet} from "react-helmet";
import TakePhotoContainer from './TakePhotoContainer';


function Home({ ...props }) {

    const [hottestOfToomar, setHottestOfToomar] = useState([]);
    const [mostSellingOfToomar, setMostSellingOfToomar] = useState([]);
    const [mainSlider, setMainSlider] = useState([]);
    const [galleryCategories, setGalleryCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await props.getAllProducts({category: 1, ordering: '-created-at'}, (data) => setHottestOfToomar(data.data.results));
            await props.getAllProducts({category: 1, ordering: '-score'}, (data) => setMostSellingOfToomar(data.data.results));
            await props.getSliders(undefined, (data) => setMainSlider(data.data.results));
            await props.getCategories('parent=1', (data) => setGalleryCategories(data.data.results));
            await props.getBlogs(undefined, (data) => setBlogs(data.data.results));
            setVisible(true)
        }
        fetchData();

    }, {})
    if(visible) {
        return (
            <ToomarContainer>
                <Helmet>
                    <title>طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی</title>
                    <meta name="title" content="طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی" />
                    <meta
                        name="description"
                        content="هر آنچه که برای مراسم عروسی نیاز دارید با مناسب ترین قیمت از طومار بخرید! متنوع ترین کارت های عروسی چاپی و دیجیتال، گیفت های عروسی، دفاتر و ست های بله برون، هدایای خاص و... ارسال (رایگان) به سراسر ایران"
                    />
                    <meta property="og:title" content="کارت عروسی طومار" />
                    <meta property="og:description" content="نخستین فروشگاه آنلاین کارت عروسی و اقلام عروسی . خرید ساده و سریع در محیطی مجازی و متنوع. کارت عروسی طومار" />
                    <meta property="og:url" content="https://toomar.co" />
                </Helmet>
                <div className={visible ? 'visible' : 'visible hide'}>
                    <ToomarCardsSwiper data={mainSlider}/>
                    {hottestOfToomar.length != 0 && <HottestOfToomar data={hottestOfToomar}/>}
                    <TopFeatureOfToomar/>
                    {mostSellingOfToomar.length != 0 && <BestSelling data={mostSellingOfToomar}/>}
                    <ToomarGallery galleryCategories={galleryCategories}/>
                    <WhyToomar/>
                    {blogs.length !=0 && <ToomarMagazine data={blogs}/>}
                    <RepetitiveQuestions/>
                    <TakePhotoContainer/>
                </div>
            </ToomarContainer>
        )
    }
    else {
        return (
            <div className={'loadingImg'}>
                <img src={Loading}/>
            </div>
        )
    }
    
}

export default connect(null, { getAllProducts, getSliders, getCategories, getBlogs })(Home);