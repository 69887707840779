import React, { useEffect } from 'react';
import './Utils.scss';
import { priceFormat } from './PriceFormat';
import { connect } from 'react-redux';
import { setFavoriteProducts } from '../../redux/actions';
import { useState } from 'react';

function ToomarCart({value , isSuggest, inMainPage, ...props}) {
    const [isFavorite, setIsFavorite] = useState(false);
    useEffect(() => {
        if(props.favoriteProducts && props.favoriteProducts.length > 0) {
            for(let i = 0; i <= props.favoriteProducts?.length; i++){
                if(value.id === props.favoriteProducts[i]?.id) {
                    setIsFavorite(true);
                }
            } 
        }
    }, [])
    // const handleIsFavorite = () => {
    //     if(props.favoriteProducts && props.favoriteProducts.length > 0) {
    //         for(let i = 0; i <= props.favoriteProducts?.length; i++){
    //             if(value.id === props.favoriteProducts[i]?.id) {
    //             }
    //         } 
    //     }
    //     else {
    //         setFavoriteProducts([...props.favoriteProducts, value]);
    //         setIsFavorite(true)
    //     }
    // }
    return (
        <a href={`/product/${value?.id}`} style={{height: "380px"}} target="_blank">
            <div className={'toomarCartContainer'}>
                <img src={value?.cover} alt={'کارت عروسی'}/>
                <div className='overflowTitle'>
                    <p style={inMainPage === true ? { fontSize: 14 } : null}>
                        {value?.title}
                    </p>
                </div>
                <div className={'footerCards'}>
                    <div className='productCode'>
                        <span style={{color: "gray"}}>{value.code}</span>
                    </div>
                    <div className={'price'}>
                        <span>
                            تومان
                        </span>
                        <span>
                            {value?.discount != 0 ?
                            priceFormat(value?.price * ((100 - value?.discount)/100)) : 
                            priceFormat(value?.price)}
                        </span>
                        {value?.discount != 0 ?
                            <span>
                                {priceFormat(value?.price)}
                            </span>
                        : null} 
                    </div>
                </div>
                {value?.discount != 0 && isSuggest != true ?
                    <div className={'offPercent'}>
                        {value.discount}%
                    </div> 
                : null }
                {isSuggest == true ?
                <div className={'suggestContainer'}>
                    <p>
                        پیشنهاد شگفت انگیز
                    </p>
                </div>
                : null} 
                <div className={"favoriteContainer"}>
                    <img src={require(isFavorite ? "../../assets/favorite.png" : "../../assets/non_favorite.png")}/>
                </div>
            </div>
        </a>
    )
}

const mapStateToProps = (state) => ({
    favoriteProducts: state.counter.favoriteProducts
})

export default connect(mapStateToProps, { setFavoriteProducts })(ToomarCart);;