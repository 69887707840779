import React, { useEffect, useState } from 'react';
import './Cart.scss';
import Delete from '../../assets/delete.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBuyingProduct, setFinishedProductDetails } from '../../redux/actions';
import { priceFormat } from '../Utils/PriceFormat';

function CartItem({
    data, 
    index, 
    setAllPrice,
    setAllDiscounted,
    setShippingCount,
    allPrice,
    allDiscounted,
    handleDeleteProduct,
    handleKoobPrice,
    ...props}) {
    const [count, setCount] = useState(data.count);
    const [situation, setSituation] = useState('در انتظار ثبت اطلاعات');
    const [price, setPrice] = useState(0);
    const [options, setOptions] = useState([]);
    const [whichProduct, setWhichProduct] = useState(null);

    // const handleDeleteProduct = () => {
    //     let locProducts = [];
    //     let locFinishedProducts = [];
    //     locProducts = [...props.buyingProducts]
    //     locFinishedProducts = [...props.finishedProductDetails];
    //     for(let i = 0; i < props.finishedProductDetails.length; i++) {
    //         if(props.finishedProductDetails[i].product == data.data.id) {
    //             locFinishedProducts.splice(i, 1);
    //             props.setFinishedProductDetails([...locFinishedProducts])
    //         }
    //     }
    //     locProducts.splice(index, 1);
    //     console.log(locProducts, locFinishedProducts)
    //     props.setBuyingProduct([...locProducts])
    //     var localAllPrice = 0;
    //     var localAllDiscount = 0;
    //     for(let i = 0; i < locProducts.length; i++) {
    //         localAllPrice = localAllPrice + locProducts[i].data.price * locProducts[i].count
    //         localAllDiscount = localAllDiscount + locProducts[i].data.discount/100 * locProducts[i].data.price * locProducts[i].count
    //     }
    //     setAllPrice(localAllPrice);
    //     setAllDiscounted(localAllDiscount);
    // }
    useEffect(() => {
        var localAllPrice = 0;
        var localAllDiscount = 0;
        for(let i = 0; i < props.finishedProductDetails.length; i++) {
            if(props.finishedProductDetails[i].product == data.data.id) {
                setSituation('آماده برای پرداخت');
                setWhichProduct(i)
            }
        }
        for(let i = 0; i < props.buyingProducts.length; i++) {
            localAllPrice = localAllPrice + props.buyingProducts[i].data.price * props.buyingProducts[i].count + props.buyingProducts[i].extraPrice
            localAllDiscount = localAllDiscount + props.buyingProducts[i].data.discount/100 * props.buyingProducts[i].data.price * props.buyingProducts[i].count
        }
        if(data.data.is_card == false && data.data.is_digital == false && data.data.has_info == false) {
            var value =  {
                "type": 'other',
                "product": data.data.id,
                "count": data.count,
                "options": data.options,
                "info": {}
            }
            var condition = false;
            for(let j = 0; j < props.finishedProductDetails.length; j++) {
                if(props.finishedProductDetails[j]?.product == data.data.id) {
                    condition = true;
                }
            }
            if(condition == false) {
                setSituation('آماده برای پرداخت')
                props.setFinishedProductDetails([...props.finishedProductDetails, value]);
            }
        }
        setAllPrice(localAllPrice);
        setAllDiscounted(localAllDiscount);
        handleKoobPrice();

    }, {})
    const handleCount = (incOrDec, value) => {
        var locData = [...props.buyingProducts]
        if(value.target.value !== "") {
            if(data.data.is_card == true && incOrDec == 0) {
                if(parseInt(count) - 1 >= 50) {
                    setAllPrice(allPrice - data.data.price - data.extraPrice/count)
                    setAllDiscounted(allDiscounted - data.data.discount/100 * data.data.price);
                    locData[index].count = parseInt(count) - 1;
                    locData[index].extraPrice = data.extraPrice - data.extraPrice / (locData[index].count + 1);
                    props.setBuyingProduct([...locData])
                    for(let i = 0; i < props.finishedProductDetails.length; i++) {
                        if(data.data.id == props.finishedProductDetails[i].product) {
                            let locFinishedProducts = [...props.finishedProductDetails];
                            locFinishedProducts[i].count = parseInt(count) - 1;
                            setFinishedProductDetails([...locData])
                        }
                    }
                    setCount(parseInt(count) - 1);
                    handleKoobPrice();
                }
            }
            else if(incOrDec == 1 ) {
                locData[index].count = parseInt(count) + 1;
                locData[index].extraPrice = data.extraPrice + data.extraPrice / (locData[index].count - 1);
                props.setBuyingProduct([...locData])
                for(let i = 0; i < props.finishedProductDetails.length; i++) {
                    if(data.data.id == props.finishedProductDetails[i].product) {
                        let locFinishedProducts = [...props.finishedProductDetails];
                        locFinishedProducts[i].count = parseInt(count) + 1;
                        setFinishedProductDetails([...locData])
                    }
                }
                setCount(parseInt(count) + 1);
                setAllPrice(allPrice + data.data.price + data.extraPrice / (locData[index].count))
                setAllDiscounted(allDiscounted + data.data.discount/100 * data.data.price);
                handleKoobPrice();
            }
            else if(incOrDec == 0) {
                setAllPrice(allPrice - data.data.price - data.extraPrice/count)
                setAllDiscounted(allDiscounted - data.data.discount/100 * data.data.price);
                locData[index].count = parseInt(count) - 1;
                locData[index].extraPrice = data.extraPrice - data.extraPrice / (locData[index].count + 1);
                props.setBuyingProduct([...locData])
                for(let i = 0; i < props.finishedProductDetails.length; i++) {
                    if(data.data.id == props.finishedProductDetails[i].product) {
                        let locFinishedProducts = [...props.finishedProductDetails];
                        locFinishedProducts[i].count = parseInt(count) - 1;
                        setFinishedProductDetails([...locData])
                    }
                }
                setCount(parseInt(count) - 1);
                handleKoobPrice();
            }
            else {
                if(value.target.value >= 50 && data.data.is_card == true) {
                    var locValue = 0;
                    var locDiscount = 0;
                    for(let i = 0; i < props.buyingProducts.length; i++) {
                        if(props.buyingProducts[i].data.id == data.data.id && value.target.value != '') {
                            locValue = locValue + value.target.value * data.data.price + (data.extraPrice/data.count)*value.target.value;
                            locDiscount = locDiscount + data.data.discount/100 * data.data.price * value.target.value
                            setAllPrice(locValue)
                            setAllDiscounted(locDiscount);
                        }
                        else if(props.buyingProducts[i].data.id != data.data.id) {
                            locValue = locValue + props.buyingProducts[i].count * props.buyingProducts[i].data.price + props.buyingProducts[i].extraPrice;
                            locDiscount = locDiscount + props.buyingProducts[i].data.discount/100 * props.buyingProducts[i].data.price * props.buyingProducts[i].count;
                            setAllPrice(locValue)
                            setAllDiscounted(locDiscount);
                        }
                    }
                    locData[index].extraPrice = value.target.value * locData[index].extraPrice / locData[index].count;
                    locData[index].count = value.target.value;
                    props.setBuyingProduct([...locData])
                    for(let i = 0; i < props.finishedProductDetails.length; i++) {
                        if(data.data.id == props.finishedProductDetails[i].product) {
                            let locFinishedProducts = [...props.finishedProductDetails];
                            locFinishedProducts[i].count = value.target.value;
                            setFinishedProductDetails([...locData])
                        }
                    }
                    setCount(value.target.value)
                    handleKoobPrice();
                }
                else if(data.data.is_card != true) {
                    var locValue = 0;
                    var locDiscount = 0;
                    for(let i = 0; i < props.buyingProducts.length; i++) {
                        if(props.buyingProducts[i].data.id == data.data.id && value.target.value != '') {
                            locValue = locValue + value.target.value * data.data.price + (data.extraPrice/data.count)*value.target.value;
                            locDiscount = locDiscount + data.data.discount/100 * data.data.price * value.target.value
                            setAllPrice(locValue)
                            setAllDiscounted(locDiscount);
                        }
                        else if(props.buyingProducts[i].data.id != data.data.id) {
                            locValue = locValue + props.buyingProducts[i].count * props.buyingProducts[i].data.price + props.buyingProducts[i].extraPrice;
                            locDiscount = locDiscount + props.buyingProducts[i].data.discount/100 * props.buyingProducts[i].data.price * props.buyingProducts[i].count;
                            setAllPrice(locValue)
                            setAllDiscounted(locDiscount);
                        }
                    }
                    locData[index].extraPrice = value.target.value * locData[index].extraPrice / locData[index].count;
                    locData[index].count = value.target.value;
                    props.setBuyingProduct([...locData])
                    for(let i = 0; i < props.finishedProductDetails.length; i++) {
                        if(data.data.id == props.finishedProductDetails[i].product) {
                            let locFinishedProducts = [...props.finishedProductDetails];
                            locFinishedProducts[i].count = value.target.value;
                            setFinishedProductDetails([...locData])
                        }
                    }
                    setCount(value.target.value)
                    handleKoobPrice();
                }
            }
        }
    }

    const handleOptions = (e) => {
        var locData = [...props.buyingProducts]
        locData[index].options = [e.target.value];
        props.setBuyingProduct([...locData])
        for(let i = 0; i < props.finishedProductDetails.length; i++) {
            if(data.data.id == props.finishedProductDetails[i].product) {
                let locFinishedProducts = [...props.finishedProductDetails];
                locFinishedProducts[i].options = [...e.target.value];
                setFinishedProductDetails([...locData])
            }
        }
    }
    return (
        <div className={'cartItemContainer'}>
            <div>
                <img src={data.data.images[0].image} alt={'کارت عروسی'}/>
                <div>
                    <p>
                        {data.data.title}
                    </p>
                    <div>
                        <p>
                            تعداد :
                        </p>
                        <div>
                            <p onClick={() => data.data.is_digital == true ? null : handleCount(1)}>
                                +
                            </p>
                            <input disabled={data.data.is_digital == true} type={'number'} value={count} onChange={(e) => handleCount(2, e)}/>
                            <p onClick={() => parseInt(count) > 1 && data.data.is_digital != true ? handleCount(0) : null}>
                                -
                            </p>
                        </div>
                        {/* {Object.keys(data.data.options).map((keyName, i) => (
                            <select defaultValue={'kabols'} value={data.options[0]} onChange={(e) => handleOptions(e)}>
                                <option disabled value={'kabols'}>
                                    {keyName}
                                </option>
                                {data.data.options[keyName].map((item, index) => (
                                <option value={item.id}>
                                    {item.value}
                                </option>
                                ))}
                            </select>
                        ))} */}
                    </div>
                    <div>
                        <p>
                            وضعیت:
                        </p>
                        <p style={situation == 'در انتظار ثبت اطلاعات' ? {color: '#E45803'} : {color: '#39B93A'}}>
                            {situation}
                        </p>
                    </div>
                    <div style={{fontSize: "10px", fontWeight: 600, marginTop: "8px"}}>
                        {props.productObj?.map(item => item.id == data.data.id ? 
                            item.obj.map(data => (
                                data.title
                            ))
                        : null )}
                    </div>
                </div>
            </div>
            <div>
                <div onClick={() => handleDeleteProduct(data, index)}>
                    <p>
                        حذف
                    </p>
                    <img src={Delete} alt={'delete'}/>
                </div>
                <div>
                    <p>
                        مبلغ کل:
                    </p>
                    <p>
                        {count != '' && count != NaN && count != null ? `${priceFormat(parseInt(data.data.price) * parseInt(count) + data.extraPrice)} تومان` : null}
                    </p>
                </div>
                {data.data.is_card || data.data.is_digital || data.data.has_info ? 
                    <Link to={!data.data.is_card && !data.data.is_digital && data.data.has_info ? `/buy-process/${index + 1}?step=3`: `/buy-process/${index + 1}`}>
                        <button>
                            {situation == 'در انتظار ثبت اطلاعات' ?  'ثبت اطلاعات' : 'مشاهده اطلاعات'}
                        </button>
                    </Link>
                : null }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    buyingProducts: state.counter.buyingProducts,
    finishedProductDetails: state.counter.finishedProductDetails,
    productObj: state.counter.productObj,
})

export default connect(mapStateToProps, { setBuyingProduct, setFinishedProductDetails })(CartItem);