

export const orderings = [
  {
    text: "مجبوب ترین ها",
    value: "-score",
  },
  {
    text: "جدیدترین ها",
    value: "-created-at",
  },
  {
    text: "گرانترین ها",
    value: "-price",
  },
  {
    text: "ارزانترین ها",
    value: "price",
  },
]