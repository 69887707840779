import Confirmation from '../../assets/confirmation.svg';
import FastDelivery from '../../assets/fastDelivery.svg';
import Gift from '../../assets/gift.svg';
import PriceTag from '../../assets/priceTag.svg';
import CreditCard from '../../assets/creditCard.svg';
import GuaranteeCertificate from '../../assets/guaranteeCertificate.svg';

export const WhyItemsObject = [
    {
        id: 0,
        src: Confirmation,
        title: 'تاییده قبل چاپ',
        description: 'تاییده قبل از چاپ چیست؟ ما به منظور بالا بردن کیفیت محصولات نهایی ورضایت شما ......'
    },
    {
        id: 1,
        src: Gift,
        title: 'تخفیفات و هدایای طومار',
        description: 'کارت عروسی طومار به تمام زوجهای عزیزی که مستقیما از خود سایت سفارش دهند تخفیفات و هدایایی....'
    },
    {
        id: 2,
        src: FastDelivery,
        title: 'ارسال سریع',
        description: 'کارت عروسی طومار بعد از ثبت سفارش ما طی 24 ساعت کاری متن طراحی شده کارت را برایتان ارسال میکند و بعد از تاییدتان....'
    },
    {
        id: 3,
        src: PriceTag,
        title: 'قیمت تمام شده کارت ها',
        description: 'منظور از قیمت تما شده کارتها چیست؟ اگر سری به بهارستان یا مغازه های کارت عروسی سطح شهر بزنید...'
    },
    {
        id: 4,
        src: GuaranteeCertificate,
        title: 'خرید آسان و مطمئن',
        description: 'سایت طومار به گونه ای طراحی شده است که شما زوج ها و کاربران عزیز میتوانید کارت مورد نظر خود را به راحتی...'
    },
    {
        id: 5,
        src: CreditCard,
        title: 'تضمین جنس و کیفیت کارها',
        description: 'منظور از جنس و کیفیت کارتها این است که ما جنس و کیفیت تمامی کارتهایی که روی سایت می باشد را تضمین می کنیم بطوریکه...'
    },

]