import React from 'react';
import Title from '../Utils/Title';
import './Home.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import ToomarCart from '../Utils/ToomarCart';
import { useMediaQuery, useTheme } from '@mui/material';

// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function HottestOfToomar({data, ...props}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={'hottetsOfToomarContainer'}>
            <Title blackText={'طومار'} goldText={'داغ‌ترین‌های'}/>
            <Swiper cssMode={true} loop={true} slidesPerView={isMobile ? 1 : 3} navigation={true} pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper"} spaceBetween={0}>
                {data.map(item => 
                    <SwiperSlide>
                        <ToomarCart inMainPage={true} value={item}/>
                    </SwiperSlide>
                )}  
            </Swiper>
        </div>
    )
}

export default HottestOfToomar;