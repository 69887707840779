import React from 'react';
import './Home.scss';
function TakePhotoContainer() {
    return (
        <div className={'takePhotoContainer'}>
            <a href='https://tarhim.toomar.co/'>
                <img style={{width: "100%"}} src={require("../../assets/tarhim.jpg")} alt="tarhim"/>
            </a>
        </div>
    )
}

export default TakePhotoContainer;