import React from 'react';
import RadioButton from '../kit/RadioButton';
import ToomarInput from '../kit/ToomarInput';
import { hours, minutes } from '../Utils/TimeObjects';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

function AddData({ 
    womanName,
    setWomanName,
    womanFamilyName,
    setWomanFamilyName,
    womanLatinName,
    setWomanLatinName,
    manName,
    setManName,
    manFamilyName,
    setManFamilyName,
    manLatinName,
    setManLatinName,
    whoIsFirst,
    setWhoIsFirst,
    isToNight,
    setIsToNight,
    startHour,
    setStartHour,
    startMin,
    setStartMin,
    endHour,
    setEndHour,
    endMin,
    setEndMin,
    address,
    setAddress,
    songName,
    setSongName,
    artistName,
    setArtistName,
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    setStep,
    onFinished, 
    ...props }) {

    const search = useLocation().search;
    const queryStep = new URLSearchParams(search).get('step');
    const params = useParams();

    const goBack = () => {
        if(queryStep == 3) {
                window.location.replace('/cart/')            
        }
        else if(props.buyingProducts[params.id - 1].data.is_digital) {
            if(props.justThisStep == true) {
                setStep(4);
                props.setJustThisStep(false)
            }
            else {
                setStep(1)
            }
        }
        else {
            if(props.justThisStep == true) {
                setStep(4);
                props.setJustThisStep(false)
            }
            else {
                setStep(2)
            }
        }
        
    }

    const handleGoNext = () => {
        if(womanName !== '' && womanFamilyName !== '' && womanLatinName !== '' && manName !== '' && manFamilyName !== '' && manLatinName !== '' && address !== '' && day !== '' && month !== '' && year !== '') {
            setStep(4);
            props.setJustThisStep(false)
        }
        else if(queryStep == 3 && womanName !== '' && womanFamilyName !== '' && womanLatinName !== '' && manName !== '' && manFamilyName !== '' && manLatinName !== '') {
            // setStep(4);
            onFinished();
            window.location.replace('/cart/');
        }
        else {
            toast.error('اطلاعات را کامل کنید')
        }
    }
    
    return (
        <div className={'addDataContainer'}>
            <p>
                اطلاعات عروس خانم
            </p>
            <div>
                <ToomarInput
                title={'نام'}
                disabled={false}
                inputValue={womanName}
                onChange={(e) => setWomanName(e.target.value)}
                />
                <ToomarInput
                title={'نام خانوادگی'}
                disabled={false}
                inputValue={womanFamilyName}
                onChange={(e) => setWomanFamilyName(e.target.value)}
                />
                <ToomarInput
                title={'نام لاتین انگلیسی'}
                disabled={false}
                inputValue={womanLatinName}
                onChange={(e) => setWomanLatinName(e.target.value)}
                />
            </div>
            <p>
                اطلاعات آقا داماد
            </p>
            <div>
                <ToomarInput
                title={'نام'}
                disabled={false}
                inputValue={manName}
                onChange={(e) => setManName(e.target.value)}
                />
                <ToomarInput
                title={'نام خانوادگی'}
                disabled={false}
                inputValue={manFamilyName}
                onChange={(e) => setManFamilyName(e.target.value)}
                />
                <ToomarInput
                title={'نام لاتین انگلیسی'}
                disabled={false}
                inputValue={manLatinName}
                onChange={(e) => setManLatinName(e.target.value)}
                />
            </div>
            {queryStep == 3 ?
            <div className={'giftDateContainer'}>
                <p>
                    تاریخ عروسی:
                </p>
                <div>
                    <ToomarInput
                    placeholder={'روز'}
                    inputValue={day}
                    onChange={(e) => setDay(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'ماه'}
                    inputValue={month}
                    onChange={(e) => setMonth(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'سال'}
                    inputValue={year}
                    onChange={(e) => setYear(e.target.value)}
                    />
                </div>
            </div>
            : null}
            {queryStep != 3 ?
            <>
            <p>
                لطفا الویت قرار گرفتن نام ها روی کارت ها را مشخص نمایید.
            </p>
            <div>
                <select value={whoIsFirst} onChange={(e) => setWhoIsFirst(e.target.value)}>
                    <option value={1}>اول عروس خانم بعد آقا داماد</option>
                    <option value={2}>اول آقا داماد بعد عروس خانم</option>
                </select>
            </div>
            <p>
                لطفا اطلاعات مراسم را با دقت وارد نمایید.
            </p>
            <div>
                <p>
                    تاریخ عروسی:
                </p>
                <div>
                    <ToomarInput
                    placeholder={'روز'}
                    inputValue={day}
                    onChange={(e) => setDay(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'ماه'}
                    inputValue={month}
                    onChange={(e) => setMonth(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'سال'}
                    inputValue={year}
                    onChange={(e) => setYear(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <p>
                        مراسم از ساعت:
                    </p>
                    <div>
                        <div>
                            <select value={startMin} onChange={(e) => setStartMin(e.target.value)}>
                                {minutes.map((item, index) => (
                                    <option value={index}>{index}</option>
                                ))}
                            </select>
                            :
                            <select value={startHour} onChange={(e) => setStartHour(e.target.value)}>
                                {hours.map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        تا ساعت:
                    </p>
                    <div>
                        <div>
                            <select disabled={isToNight == true ? true : false} value={endMin} onChange={(e) => setEndMin(e.target.value)}>
                                {minutes.map((item, index) => (
                                    <option value={index}>{index}</option>
                                ))}
                            </select>
                            :
                            <select disabled={isToNight == true ? true : false} value={endHour} onChange={(e) => setEndHour(e.target.value)}>
                                {hours.map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <p>
                                تا پاسی از شب
                            </p>
                            <RadioButton checked={isToNight} onChange={() => setIsToNight(!isToNight)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ToomarInput
                title={'ادرس تالار'}
                disabled={false}
                inputValue={address}
                onChange={(e) => setAddress(e.target.value)}
                />
            </div>
            {/* {props.buyingProducts[params.id - 1].data.is_digital != true || props.  ?
            <>
                <p>
                    لطفا در صورت تمایل آهنگ پس زمینه دلخواه خود را وارد نمایید.
                </p>
                <div>
                    <ToomarInput
                    title={'نام آهنگ'}
                    disabled={false}
                    inputValue={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    />
                    <ToomarInput
                    title={'نام خواننده'}
                    disabled={false}
                    inputValue={artistName}
                    onChange={(e) => setArtistName(e.target.value)}
                    />
                </div>
            </>
            : null} */}
            </> : null}
            <div className='changeStepContainer'>
                <button onClick={goBack}>
                    بازگشت
                </button>
                <button onClick={handleGoNext}>
                    تایید و ادامه
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    buyingProducts: state.counter.buyingProducts,
})

export default connect(mapStateToProps, null)(AddData);
