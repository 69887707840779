import React from 'react';
import Title from '../Utils/Title';
import './Home.scss';
import ArrowLeftLong from '../../assets/arrowLeftLong.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, {
  Navigation,Mousewheel
} from 'swiper';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'jalali-moment'

// install Swiper modules
SwiperCore.use([Navigation,Mousewheel]);


function ToomarMagazine({data, ...props}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={'toomarMagazineContainer'}>
            <Title blackText={'طومار'} goldText={'مجله'}/>
            <Swiper cssMode={true} loop={true} navigation={true} slidesPerView={1} mousewheel={true} className={"toomarMagazineSwiper"}>
                {data?.map((item, index) =>
                    isMobile ? 
                    <SwiperSlide>
                        <Link to={`/magazine/${data[index]?.id}`}>
                            <div className={'toomarMagazineItemsContainer'}>
                                <div className={'toomarMagazineItemRight'}>
                                    <img src={data[index]?.image} alt={'کارت عروسی'}/>
                                    <p>
                                        {data[index]?.title}
                                    </p>
                                    <div className={'goMore'}>
                                        <div>
                                            <p>
                                                ادامه مطلب
                                            </p>
                                            <img src={ArrowLeftLong} alt={'more'}/>
                                        </div>
                                    </div>
                                    <div>{moment(data[index]?.created_at).format("jYYYY/jMM/jDD")}</div>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>
                    : index % 3 == 0 && !isMobile ?
                    <SwiperSlide>
                        <div className={'toomarMagazineItemsContainer'}>
                            <Link to={`/magazine/${data[index]?.id}`}>
                                <div className={'toomarMagazineItemRight'}>
                                    <img src={data[index]?.image} alt={'کارت عروسی'}/>
                                    <p>
                                        {data[index]?.title}
                                    </p>
                                    <div className={'goMore'}>
                                        <div>
                                            <p>
                                                ادامه مطلب
                                            </p>
                                            <img src={ArrowLeftLong} alt={'more'}/>
                                        </div>
                                    </div>
                                    <div>{moment(data[index]?.created_at).format("jYYYY/jMM/jDD")}</div>
                                </div>
                            </Link>
                            <div className={'toomarMagazineItemsContainerColumn2'}>
                                {data.length > 1 ?
                                <Link to={`/magazine/${data[index + 1]?.id}`}>
                                    <div className={'toomarMagazineItemLeft'}>
                                        <img src={data[index + 1]?.image} alt={'کارت عروسی'}/>
                                        <div className={'detailsInLeft'}>
                                            <p>
                                                {data[index + 1]?.title}
                                            </p>
                                            <div className={'goMore'}>
                                                <p>
                                                    ادامه مطلب
                                                </p>
                                                <img src={ArrowLeftLong} alt={'more'}/>
                                            </div>
                                        </div>
                                        <div>{moment(data[index+1]?.created_at).format("jYYYY/jMM/jDD")}</div>
                                    </div>
                                </Link>
                                : null}
                                {data.length > 2 ?
                                <Link to={`/magazine/${data[index + 2]?.id}`}>
                                    <div className={'toomarMagazineItemLeft'}>
                                        <img src={data[index + 2]?.image} alt={'کارت عروسی'}/>
                                        <div className={'detailsInLeft'}>
                                            <p>
                                                {data[index + 2]?.title}
                                            </p>
                                            <div className={'goMore'}>
                                                <p>
                                                    ادامه مطلب
                                                </p>
                                                <img src={ArrowLeftLong} alt={'more'}/>
                                            </div>
                                        </div>
                                        <div>{moment(data[index+2]?.created_at).format("jYYYY/jMM/jDD")}</div>
                                    </div>
                                </Link>
                                : null}
                            </div>
                        </div>
                    </SwiperSlide>
                    : null
                )}
            </Swiper>
        </div>
    )
}

export default ToomarMagazine;